import Vue from 'vue'
import App from '@/App.vue'
import router from "@/router"
import store from "@/store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.config.productionTip = false
Vue.use(ElementUI);
// 在此处引入公共样式
require("@/assets/css/global.scss");

new Vue({
  router,
  store,
  mounted() {
    // 在一些浏览器比如苹果Safari，在定义meta之后，屏幕禁止缩放没有效果
    window.onload = function () {
      document.addEventListener("touchstart", function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (event) {
        event.preventDefault();
      });
    };

    window.addEventListener(
        "resize",
        () => {
          rem();
        },
        false
    );

    function rem() {
      document.documentElement.style.fontSize =
          (document.documentElement.clientWidth / 1920) * 10 + "px";
    }

    rem();
  },
  render: h => h(App),
}).$mount('#app')
