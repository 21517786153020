import Vue from "vue";
import VueRouter from "vue-router";
//import {VueRouter, createWebHashHistory} from "vue-router";
//import { createRouter, createWebHashHistory } from 'vue-router'
//import { createRouter, createWebHistory } from "vue-router";

// 防止连续点击多次路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this,location).catch((err) => err)
}
Vue.use(VueRouter);

// 在此处引入所有字体
require("@/assets/fonts/roboto.css");

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/caseview",
    name: "caseView",
    component: () => import("@/views/caseView.vue"),
  }/*,
  {
    path: "/privacy",
    name: "PrivacyView",
    component: () => import("@/views/PrivacyView.vue"),
    props: true,
  },
  {
    path: "/terms",
    name: "TermsView",
    component: () => import("@/views/TermsView.vue"),
    props: true,
  }*/

];


const router = new VueRouter({
  //mode: 'history', // comment to  
  //mode: 'history', // 打开可去掉url中的# 但刷新报404
  base: process.env.BASE_URL,
  //history: createWebHashHistory(process.env.BASE_URL),
  routes,
});


/*
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
*/
export default router;
